var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    staticClass: "card-transaction",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Kas")]), _c('b-link', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: 'Klik disini untuk ke menu Kas',
      expression: "'Klik disini untuk ke menu Kas'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.push("/kas");
      }
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "ExternalLinkIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-card-body', [_vm._l(_vm.id_kas, function (transaction) {
    return _c('div', {
      key: transaction.id,
      staticClass: "transaction-item"
    }, [_c('b-media', {
      attrs: {
        "no-body": ""
      }
    }, [_c('b-media-aside', [_c('b-avatar', {
      attrs: {
        "rounded": "",
        "size": "42",
        "variant": transaction.saldo <= 0 ? 'light-danger' : 'light-success'
      }
    }, [_c('feather-icon', {
      attrs: {
        "size": "18",
        "icon": transaction.saldo <= 0 ? 'PocketIcon' : 'DollarSignIcon'
      }
    })], 1)], 1), _c('b-media-body', [_c('h6', {
      staticClass: "transaction-title"
    }, [_vm._v(" " + _vm._s(transaction.nama) + " ")]), _c('small', [_vm._v(_vm._s(transaction.keterangan))])])], 1), _c('div', {
      staticClass: "font-weight-bolder",
      class: transaction.saldo <= 0 ? 'text-danger' : 'text-success'
    }, [_vm._v(" Rp. " + _vm._s(_vm.formatRupiah(transaction.saldo)) + " ")])], 1);
  }), _c('hr'), _c('div', {
    staticClass: "transaction-item"
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "size": "42",
      "variant": _vm.getNominal(_vm.id_kas) <= 0 ? 'light-danger' : 'light-success'
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "18",
      "icon": _vm.getNominal(_vm.id_kas) <= 0 ? 'PocketIcon' : 'DollarSignIcon'
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "transaction-title"
  }, [_vm._v(" Saldo ")]), _c('small', [_vm._v("Terkini")])])], 1), _c('div', {
    staticClass: "font-weight-bolder",
    class: _vm.getNominal(_vm.id_kas) <= 0 ? 'text-danger' : 'text-success'
  }, [_vm._v(" Rp. " + _vm._s(_vm.formatRupiah(_vm.getNominal(_vm.id_kas))) + " ")])], 1)], 2)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    staticClass: "card-statistics",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Rekap Penjualan")]), _c('b-card-text', {
    staticClass: "font-small-2 mr-25 mb-0"
  }, [_c('b-link', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: 'Klik disini untuk ke menu Transaksi',
      expression: "'Klik disini untuk ke menu Transaksi'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.push("/transaksi-penjualan");
      }
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "ExternalLinkIcon",
      "size": "18"
    }
  })], 1)], 1)], 1), _c('b-card-body', {
    staticClass: "statistics-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-md-0",
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "size": "48",
      "variant": "success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "CheckCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.totaltransaksimasuklunas ? _vm.formatRupiah(_vm.totaltransaksimasuklunas) : 0) + " ")]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Lunas ")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0",
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "size": "48",
      "variant": "danger"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "XCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.totaltransaksimasukbelum ? _vm.formatRupiah(_vm.totaltransaksimasukbelum) : 0) + " ")]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Belum Lunas ")])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_vm.loaded ? _c('chartjs-component-doughnut-chart', {
    staticClass: "mt-2 mb-1",
    attrs: {
      "height": 275,
      "data": _vm.doughnutChart.data ? _vm.doughnutChart.data : null,
      "options": _vm.doughnutChart ? _vm.doughnutChart.options : null
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "icon": "UsersIcon",
      "statistic": _vm.membertotal ? _vm.formatRupiah(_vm.membertotal) : 0,
      "statistic-title": "Member",
      "color": "info"
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "icon": "PackageIcon",
      "statistic": _vm.barangtotal ? _vm.formatRupiah(_vm.barangtotal) : 0,
      "statistic-title": "Barang",
      "color": "warning"
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "icon": "FileIcon",
      "statistic": _vm.totaltransaksi ? _vm.formatRupiah(_vm.totaltransaksi) : 0,
      "statistic-title": "Transaksi Penjualan",
      "color": "primary"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }